<template>
<section class="animated-circle-card-slick">
    <VueSlickCarousel class="slick computer" v-if="items.length>0" v-bind="settings">
        <template v-for="(item,i) in items">
            <div v-if="item.ulid!='01FY46MX2EW85PP30KQ6S9BXG3'" class="card card-content mt-4 pointer" style="width: 18rem" :key="i">
                <template v-if="item.image">
                    <picture>
                        <source class="card-img-top"  :srcset="item.image.content.webpImage" type="image/webp">
                        <img class="card-img-top"  :src="item.image.content.originalImage" alt="Card image cap">
                    </picture>
                </template>
                <img v-else class="card-img-top" src="@/assets/images/import/default-image.jpg" alt="image">
                <div class="card-body">
                    <h5 class="card-title text-center underline">{{item[title]}}</h5>
                </div>
            </div>
        </template>

    </VueSlickCarousel>

    <!-- MOBILE -->
    <div class="row product-item-wrapper mobile" >
        <div :class="{scrollcards: scrollcards}" :id="id">
            <template v-for="(item, itemID) in items" >
                <div v-if="item.ulid!='01FY46MX2EW85PP30KQ6S9BXG3'" class="col-lg-4 col-md-6 col-sm-6 col-12 mx-2 product-item stretch-card" :key="itemID">
                    <div class="card" style="width: 18rem;">
                        <template v-if="item.image">
                            <picture>
                                <source class="card-img-top"  :srcset="item.image.content.webpImage" type="image/webp">
                                <img class="card-img-top"  :src="item.image.content.originalImage" alt="Card image cap">
                            </picture>
                        </template>
                        <img v-else class="card-img-top" src="@/assets/images/import/default-image.jpg" alt="image">
                        <div class="card-body d-flex justify-content-center">
                            <h5 class="card-title text-center underline">{{item[title]}}</h5>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    name: 'animated-circle-card-slick',
    components: {
        VueSlickCarousel
    },
    data () {
        return {
            testArray:[1, 2, 3, 7,4,5,6,8],
            test2:[
                {test:'salut'},
                {test:'salut'},
                {test:'salut'},
                {test:'salut'},
                {test:'salut'},
                {test:'salut'},
                {test:'salut'},
                {test:'salut'},
                {test:'salut'},
                {test:'salut'},
                {test:'salut'}
            ],
            items: [],
            settings: {
                // "dots": true,
                // "infinite": true,
                // "slidesToShow": 4,
                // "slidesToScroll": 2,
                // "autoplay": true,
                // "autoplaySpeed": 3000,
                // "pauseOnDotsHover": true,
                // "pauseOnFocus": true,
                // "pauseOnHover": true,
                // "cssEase": "linear",
                "arrows": false,
                "dots": false,
                "infinite": true,
                "slidesToShow": 4,
                "slidesToScroll": 1,
                "autoplay": true,
                "speed": 3000,
                "autoplaySpeed": 3000,
                "cssEase": "linear",
                // "swipe":true,
                "swipeToSlide": true, //changer le positionnement de l'image lors du scroll
                // "waitForAnimate":false,
                "draggable":true
                // "touchMove": true
            }
        }
    },
    computed: {
        slidesNumber () {
            return this.items.length
        }
    },
    props: {
        id: String,
        scrollcards: Boolean,
        entitytype: String,
        card: Array,
        title: String,
        description:String,
        price: String,
        column: String,
        sectionTitle: String,
        editable: Boolean
    },
    methods: {
        getEntitytype () {
            if(this.entitytype) {
                axios.get(this.entitytype)
                .then ( resEntitytype => {
                    this.items= resEntitytype.data.result
                    if (this.items.isactive) {
                          this.items=  this.items.filter(element => element.isactive)
                    }
                })
                .catch ( errEntitytype => {console.log(errEntitytype)})
            }else if(this.card){
                this.items = this.card
            }else{
                this.items=[]
            }
        },
        //scrollcards
        scrollright() {
            document.getElementById(`${this.id}`).scrollLeft += 1000;
        },
        scrollleft() {
            document.getElementById(`${this.id}`).scrollLeft -= 1000;
        },
        deleteCard(item) {
            let index = this.items.indexOf(item)
            this.items.splice(index, 1)
        },
        services(){
            this.$router.push({
                name:'services'
            })
        },
        nextslide(){

        }
    },
    mounted () {
        this.getEntitytype() 
        //établir le nombre de slide de base
        // document.documentElement.style.setProperty("--slidesNumber", this.slidesNumber)
        // document.documentElement.style.setProperty("--testanimation", this.testanimation)
        // document.documentElement.style.setProperty("--testcolor", "purple")
    }
}
</script>

<style scoped>
.product-item{
    display: inline-block;
    text-decoration: none;
    height: auto; 
    width: 288px;
}

/* scrollcards */
 .scrollcards {
  overflow-x: auto;
  white-space: nowrap
}

.scrollcards {
    scrollbar-width: thin
}

div.scrollcards .card {
    display: inline-block;
    /* padding: 14px; */
    text-decoration: none;
    height: auto; 
    width: 300px;
}

img{
    /* forme des images */
    border-radius: 50%;
    width: 13rem;
    height: 200px;
    object-fit: cover;
    margin-left: 2rem
}
.card{
    /* animation au ralenti lors du zoom hover */
    transition: transform 0.5s !important;
    background: none;
    border:#fff !important
}
.card:hover{
    transform: scale(1.1) !important;
}

img {
    -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  pointer-events: none;
  user-select: none;
  user-drag: none
}
</style>