<template>
    <section>
        <div  class="col-md-12 col-sm-12 institute" data-aos="fade-left" data-aos-duration="3000">
            <div class="row">
                <div class="px-5 col-md-6 col-sm-12" data-aos="flip-up" data-aos-duration="3000">
                    <img v-b-modal.nohem src="@/assets/images/import/nohem.png" alt="">
                </div>
                <div class="px-5 col-md-6 col-sm-12" data-aos="flip-right" data-aos-duration="2000">
                    <img v-b-modal.beauty src="@/assets/images/import/beauty-garden.png" alt="">
                </div>
                <!-- <div class="px-5 col-md-6 col-sm-12" data-aos="flip-left" data-aos-duration="3000">
                    <img v-b-modal.kure src="@/assets/images/import/kure.png" alt="">
                </div> -->
                <div class="px-5 offset-md-3 col-md-6 col-sm-12" data-aos="flip-down" data-aos-duration="2000">
                    <img v-b-modal.paris src="@/assets/images/import/1944-paris.png" alt="">
                </div>
            </div>
            <div class="row">
            <!-- modal pour Nohem-->
                <b-modal id="nohem" scrollable modal-class="institute-modal" class="institute"  size="md" hide-footer>
                    <div class="d-flex justify-content-center">
                        <img v-b-modal.nohem src="@/assets/images/import/nohem-gold.png" alt="">
                    </div>
                    <p><u class="gold-yellow">Éthique & engagée </u></p>
                    <p>Nohèm achète ses ingrédients auprès des femmes du Sud, productrices des matières 1ères au cœur des formulations cosmétiques, à un prix juste, dans l’esprit d’un échange équitable, d’une relation commerciale qui permet à ces femmes de vivre décemment de leur récolte.</p>
                    <p><u class="gold-yellow">Soin certifié bio & responsable </u></p>
                    <p>
                        La marque utilise des ingrédients naturels (99% à 100% du total des ingrédients), biologiques (15% à 65% du total des ingrédients) et issus du commerce équitable (5 à 25% des ingrédients), certifiés par des labels bio et équitable d’Ecocert.
                        <br> Portée par une démarche globale d’éco-conception, la marque Nohèm est soucieuse du restpect de l’environnement. Les cosmétiques sont créés en Bretagne.

                    </p>
                    <p><u class="gold-yellow">Voyage</u></p>
                    <p>
                        La fondatrice de la marque, Noémie Amisse, ayant beaucoup voyagé a souhaité créer des cosmétiques et des soins venant du monde pour partage avec chaque femme les secrets de la beauté.
                    </p>
                </b-modal>
                <!-- modal Beauty -->
                <b-modal id="beauty" scrollable modal-class="institute-modal" class="institute"  size="md" hide-footer>
                    <div class="d-flex justify-content-center">
                        <img v-b-modal.beauty src="@/assets/images/import/beauty-garden-gold.png" alt="">
                    </div>
                    <p>C’est en prenant soin de son jardin, à Auriac, en Corrèze, que Bernard Mas, fondateur de la marque s’est posé la question…</p>
                    <p>Pour y répondre, il a réuni autour de lui une équipe de passionnées de bio avec une experte en herboristerie, un maraicher, et une passionnée d’écoconception. Ensemble, ils ont créé des cosmétiques Bio.</p>
                    <br><p>De la graine ...</p>
                    <p>Leur jardin est certifié bio et les fleurs et légumes grandissent dans un environnement privilégié, loin de la pollution avec pour seul engrais le soleil et l’eau de pluie.</p>
                    <br><p>A la crème ...</p>
                    <p>L’herboriste développe chaque formule. Des soins uniques créés sur mesure pour mettre en avant les bienfaits du potager. Ses ingrédients 100% naturels, des mélanges créatifs et actifs.  Les produits sont fabriqués dans leur atelier, en Corrèze.</p>
                </b-modal>
                <!-- modal Kure -->
                <b-modal id="kure" modal-class="institute-modal" class="institute"  size="lg" hide-footer>
                    <div class="d-flex justify-content-center">
                        <img v-b-modal.kure src="@/assets/images/import/kure-gold.png" alt="">
                    </div>
                    <p>Kure Bazaar est le vernis à ongles d’une nouvelle génération qui allie l’éthique d’une formule écologique aux couleurs vibrantes de la mode Parisienne.</p>
                    <p>Après plusieurs années de recherche, une innovation technologique a permis de diminuer sensiblement les substances chimiques, sans compromis : tenue, brillance et éclat des couleurs exceptionnels.
                    Kure Bazaar préserve la vitalité des ongles en respectant leur cycle naturel de renouvellement.
                    </p>
                    <p>Formule jusqu’à 90 % bio sourcée, enrichie aux extraits de bombes et vitaminée, SANS ajout de Toluène, formaldéhyde, dibutyl-phtalate, camphre synthétique, TPHP, paraben, xylène, styrène, Benzophénone-1, - 3 Nanocouleur.</p>
                </b-modal>
                <!-- modal Paris -->
                <b-modal id="paris" scrollable modal-class="institute-modal" class="institute"  size="md" hide-footer>
                    <div class="d-flex justify-content-center">
                        <img v-b-modal.paris src="@/assets/images/import/1944-paris-gold.png" alt="">
                    </div>
                    <p><u class="gold-yellow">La beauté écoresponsable</u></p>
                    <p>Les ingrédients des formules sont tous rigoureusement sélectionnés pour leurs performances ciblées.</p>
                    <p>Sur la peau, les lèvres ou les yeux, chaque produit renferme une action protectrice et nourrissante en fonction de la zone du visage.</p>                
                    <ul>
                        <li>Cacao : antioxydant</li>
                        <li>Baobab : régénère et hydrate</li>
                        <li>Noix de coco : nourrissant</li>
                        <li>Ricin : nourrit & accélère la pousse</li>
                        <li>sésame: antioxydant</li>
                        <li>tournesol: nourrissant, hydratant, régénérateur</li>
                        <li>Cameline : anti-âge, régénérant, cicatrisant</li>
                        <li>Camomille : apaisant, tonifiant</li>
                        <li>Beurre de Karité : nourrissant, apaisant …</li>
                    </ul>
                    <p>Maquillage adapté aux attentes de toutes les femmes, tous les âges, tous les styles.</p>
                </b-modal>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'institute',
    methods: {
        blurActive() {
            this.root.style.setProperty("--blur", "blur(15px)");
        }
    }
}
</script>

<style scoped>
/* Marques de l'institut */
.institute img {
    width: 100%;
    transition: 0.5s ease-out;
}

::v-deep .institute-modal {
    background-color:rgba(0, 0, 0, 0.294);
    scrollbar-color: rgba(153, 153, 153, 0.397) #3330;
    
}
::v-deep .institute-modal::-webkit-scrollbar {
    width: 10px;
    scrollbar-width: thin !important;
}

::v-deep .institute-modal > .modal-dialog > .modal-content {
  background-color: #0000;
  border: none;
  color: white
}

::v-deep .institute-modal img {
    width: 12rem;
    margin-bottom: 1rem
}
::v-deep .institute-modal > .modal-dialog > .modal-content > .modal-header {
    border: none;
}

::v-deep .modal-dialog-scrollable .modal-body {
    scrollbar-width: thin;
}
::v-deep .modal-dialog.modal-dialog-scrollable {
    margin-top: 0px
}

.institute img:hover{
    transform: scale(1.2);
}

/* bouton fermer */
::v-deep button.close {
    color: #fff !important;
    font-size: 3rem;
}
</style>