<template>
    <section>
        <VueSlickCarousel v-bind="settings">
            <div class="slide">
                <!-- écran large -->
                <div class="part-one d-flex flex-column align-self-center text-center px-4 computer">
                    <h3 class="mb-3">Prestations</h3>
                    <p>De la tête aux pieds, des soins au maquillage en passant par le conseil en image, de l'Afrique à l'Asie en passant par l'Europe, venez voyager vers la beauté et le bien-être !</p>
                </div>
                <div class="part-two computer">
                    <picture>
                        <source class="card-img-top"  srcset="../../assets/images/import/prestations.webp" type="image/webp">
                        <img class="carousel-img" slot="img" src="../../assets/images/import/prestations.png" alt="slide image" loading="lazy">
                    </picture>
                </div>
                <!-- mobile -->
                <div class="position-relative carousel-mobile mobile">
                    <picture>
                        <source class="carousel-img"  srcset="../../assets/images/import/prestations.webp" type="image/webp">
                        <img class="carousel-img" slot="img" src="../../assets/images/import/prestations.png" alt="slide image" loading="lazy">
                    </picture>
                    <div class="position-absolute d-flex">
                        <div class="text d-flex flex-column text-center align-items-center align-self-center px-4 m-1 py-4">
                            <h3 class="mb-4">Prestations</h3>
                            <p>De la tête aux pieds, des soins au maquillage en passant par le conseil en image, de l'Afrique à l'Asie en passant par l'Europe, venez voyager vers la beauté et le bien-être !</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="slide">
                 <!-- écran large -->
                <div class="part-one computer">
                    <picture>
                        <source class="card-img-top"  srcset="../../assets/images/import/bathroom.webp" type="image/webp" >
                        <img class="carousel-img" slot="img" src="../../assets/images/import/bathroom.jpg" alt="slide image" loading="lazy">
                    </picture>
                </div>
                <div class="part-two d-flex flex-column align-self-center text-center px-5 computer">
                        <h3>Mon institut</h3>
                        <p class="mb-3">Dans une rue très passante et commerçante de Gaillac, aucun stress pour se garer, vous aurez à votre disposition un parking devant l'institut.
                            Vous trouverez deux cabines de soin de 12m² avec un esprit cocooning ainsi qu'un magasin lumineux comprenant un espace maquillage et onglerie. 
                        </p>
                        <!-- <router-link to="/services"><button class="btn btn-gradient-primary" >En savoir plus</button></router-link>  -->
                </div>
                <!--  mobile -->
                <div class="position-relative carousel-mobile mobile">
                    <picture>
                        <source class="carousel-img"  srcset="../../assets/images/import/bathroom.webp" type="image/webp" >
                        <img class="carousel-img" slot="img" src="../../assets/images/import/bathroom.jpg" alt="slide image" loading="lazy">
                    </picture>
                    <div class="position-absolute d-flex">
                        <div class="text d-flex flex-column text-center align-items-center align-self-center px-4 m-1 py-4">
                            <h3 class="mb-4">Mon institut</h3>
                            <p class="mb-4">Dans une rue très passante et commerçante de Gaillac, aucun stress pour se garer, vous aurez à votre disposition un parking devant l'institut.
                                Vous trouverez deux cabines de soin de 12m² avec un esprit cocooning ainsi qu'un magasin lumineux comprenant un espace maquillage et onglerie. 
                            </p>
                            <!-- <router-link to="/services"><button class="btn btn-gradient-primary mb-5" >En savoir plus</button></router-link>  -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="slide">
                <!-- écran large -->
                <div class="part-one d-flex flex-column align-self-center text-center px-5 computer">
                    <h3 class="mb-4">Ma sélection de produits</h3>
                    <p>Je fonctionne exclusivement avec les marques Nohèm, 1944 Paris et Beauty Garden. Je les utilise depuis longtemps et je les ai sélectionnées pour la qualité de leurs produits. La plupart des produits sont issus d'extrait naturel et portent le label BIO.
                    </p>
                    <router-link to="/boutique"><button class="btn btn-gradient-primary" >Boutique</button></router-link>    
                </div>
                <div class="part-two computer">
                    <picture>
                        <source class="card-img-top"  srcset="../../assets/images/import/produits.webp" type="image/webp">
                        <img class="carousel-img" src="../../assets/images/import/produits.jpg" alt="slide image" loading="lazy">
                    </picture>
                </div>
                <!-- mobile -->
                <div class="position-relative carousel-mobile mobile">
                    <picture>
                        <source srcset="../../assets/images/import/produits.webp" type="image/webp">
                        <img class="carousel-img" src="../../assets/images/import/produits.jpg" alt="slide image" loading="lazy">
                    </picture>
                    <div class="position-absolute d-flex">
                    <div class="text d-flex flex-column text-center align-items-center align-self-center px-4 m-1 py-4">
                        <h3>Ma sélection de produits</h3>
                        <p>Je fonctionne exclusivement avec les marques Nohèm, 1944 Paris et Beauty Garden. Je les utilise depuis longtemps et je les ai sélectionnées pour la qualité de leurs produits. La plupart des produits sont issus d'extrait naturel et portent le label BIO.
                        </p>
                        <router-link to="/boutique"><button class="btn btn-gradient-primary" >Boutique</button></router-link> 
                    </div>
                    </div>
                </div>
            </div>
        </VueSlickCarousel>
    </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
    name: "carousel-slick",
    components: {
        VueSlickCarousel
    },
    data () {
        return {
            settings: {
                "dots": true,
                "dotsClass": "slick-dots custom-dot-class",
                "edgeFriction": 0.35,
                "infinite": true,
                "speed": 800,
                "autoplay": true,
                "autoplaySpeed":6500,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "pauseOnDotsHover": true,
                "pauseOnFocus": true,
                "pauseOnHover": true
            }
        }
    },
}
</script>
<style scoped>
@media screen and (max-width: 992px) {
    /* si sur mobile */
    .carousel-img {
        height: 37rem !important;
        object-fit: cover;
    }
    .carousel-mobile .position-absolute {
        top: 0;
        height: 100%;
    }
    .text {
        background: rgba(255, 255, 255, 0.808);
    }
}
@media screen and (min-width: 992px) {
    /* si sur écran large (ordinateur) */ 
    .slide {
        display: grid !important;
        grid-template-columns: 50% 50%;
    }
    img {
        width: 100%;
        object-fit: cover;
    }
}

img{
    width: 100%;
    pointer-events: none;
}

::v-deep .slick-dots {
    margin-bottom: 30px
}
</style>